
<div class="container">
  <div nz-row nzGutter="16" class="toolbar">
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="logo">
        <img
          src="assets/images/Group 1633.png"
          alt="logo"
          (click)="toHomePage()"
        />
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="8">
          <div class="title"><h2 class="text-title">E-biller</h2></div>
        </div>
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="gutter-box" nz-row nzJustify="end">
        <div nz-col nzSpan="12"></div>
        <div nz-col nzSpan="12" class="home-wrp">
          <button
            nz-button
            nzType="default"
            class="home-btn"
            (click)="toHomePage()"
          >
            Home
            <i nz-icon nzType="home" nzTheme="outline"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bluescreen">
  
    <div class="loginscreen shadow">
      <div class="title-login">
        Reset Your Password !
      </div>

      <form
        nz-form
        [formGroup]="loginForm"
        class="login-form"
        (ngSubmit)="resetPassword()"
      >
        <nz-form-item>
          <nz-form-control nzErrorTip="Please enter your New Password">
            <nz-input-group nzPrefixIcon="lock">
              <input             
                nz-input
                formControlName="newPass"
                id="newPass"
                name="password"
                type="password"
                maxlength="50"               
                placeholder="New Password"
                maxlength="50"               
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please Confirm Password">
            <nz-input-group nzPrefixIcon="lock">
              <input             
                nz-input
                formControlName="confirmPass"
                id="confirmPass"
                name="password"
                type="password"
                maxlength="50"               
                placeholder="Confirm Password"
                maxlength="50"               
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <button
          nz-button
          class="login-form-button"
          [nzType]="'primary'"
          [disabled]="loginForm.invalid || loading"
          (click)="resetPassword()"
          nzBlock
          [nzLoading]="loading"
        >
         Reset Set Password<i nz-icon nzType="lock" nzTheme="outline"></i>
        </button>
        <div nz-row>
          <a
            class="login-form-forgot"
            class="login-form-forgot"
            routerLink="/login/terms"
            >Terms of Service</a
          >
        </div>
      </form>
    </div>
  </div>
</div>

