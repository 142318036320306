<div class="container">
  <div nz-row nzGutter="16" class="toolbar">
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="logo">
        <img src="assets/images/Group 1633.png" alt="logo" (click)="toHomePage()" />
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="8">
          <div class="title">
            <h2 class="text-title">E-biller</h2>
          </div>
        </div>
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="gutter-box" nz-row nzJustify="end">
        <div nz-col nzSpan="12"></div>
        <div nz-col nzSpan="12" class="home-wrp">
          <button nz-button nzType="default" class="home-btn" (click)="toHomePage()">
            Home
            <i nz-icon nzType="home" nzTheme="outline"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bluescreen">

    <div class="loginscreen shadow">
      <div class="title-login">
        Approve Biller
      </div>

      <div style="margin-top:10vh;">
        <div nz-row nzGutter="9">
          <p style="font-size: 1.5em;font-weight:3000%;text-align: center;color: #0033a1;">
            An exciting enterprise Bill
          </p>

        </div>
        <div nz-row nzGutter="9">
          <p style="font-size: 1.5em;font-weight:3000%;text-align: center;color: #0033a1;">
            presentment platform.
          </p>

        </div>
        <div nz-row nzGutter="9">
          <p style="font-size: 1.5em;font-weight:3000%;text-align: center;color: #0033a1;">
            You have been invited to be a payer
          </p>
        </div>




      </div>
      <div style="padding-left: 2vh;padding-right: 2vh;margin-top:3vh;">
        <button nz-button class="login-form-button" [nzType]="'primary'" (click)="approveBiller()" nzBlock
          [nzLoading]="loading">
          Approve Biller<i nz-icon nzType="check" nzTheme="outline"></i>
        </button>

      </div>

    </div>
  </div>
</div>