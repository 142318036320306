<div class="container">
  <div nz-row nzGutter="16" class="toolbar">
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="logo">
        <img
          src="assets/images/Group 1633.png"
          alt="logo"
          (click)="toHomePage()"
        />
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="8">
          <div class="title"><h2 class="text-title">E-biller</h2></div>
        </div>
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="gutter-box" nz-row nzJustify="end">
        <div nz-col nzSpan="12"></div>
        <div nz-col nzSpan="12" class="home-wrp">
          <button
            nz-button
            nzType="default"
            class="home-btn"
            (click)="toHomePage()"
          >
            Home
            <i nz-icon nzType="home" nzTheme="outline"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bluescreen">
   
    <div class="loginscreen shadow">
      <div class="title-login">
        Create Profile
      </div>
      <div class="login-container">
        <form
          nz-form
          [formGroup]="registerForm"
          class="login-form"
          (ngSubmit)="registerBiller()"
        >
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your Company  Name">
              <nz-input-group nzPrefixIcon="solution">
                <input
                  type="text"
                  nz-input
                  formControlName="companyName"
                  placeholder="Company name"
                  id="companyName"
                  autocomplete="off"
                  maxlength="50"
                  size="100"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your First  Name">
              <nz-input-group nzPrefixIcon="user">
                <input
                  type="text"
                  nz-input
                  formControlName="firstName"
                  placeholder="First name"
                  id="firstName"
                  autocomplete="off"
                  maxlength="50"
                  size="100"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your Last  Name">
              <nz-input-group nzPrefixIcon="user">
                <input
                  type="text"
                  nz-input
                  formControlName="lastName"
                  placeholder="Last name"
                  id="lastName"
                  autocomplete="off"
                  maxlength="50"
                  size="100"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your Phone Number">
              <input
                type="text"
                class="input-size"
                style="width:113%;"
                nz-input
                placeholder="0712345678"
                formControlName="phonenumber"
                ng2TelInput
                [ng2TelInputOptions]="{ initialCountry: 'ke' }"
                (keyup)="getNumber()"
                (hasError)="hasError($event)"
                maxlength="10"
              />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your password">
              <nz-input-group nzPrefixIcon="lock">
                <input
                  type="password"
                  nz-input
                  formControlName="newpassword"
                  placeholder="Enter New Password"
                  id="password"
                  autocomplete="off"
                  maxlength="50"
                  size="100"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your confirmed password">
              <nz-input-group nzPrefixIcon="lock">
                <input
                  type="password"
                  nz-input
                  formControlName="confirmpassword"
                  placeholder="Confirm Password"
                  id=" confirmpassword"
                  autocomplete="off"
                  maxlength="50"
                  size="100"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <button
            nz-button
            class="login-form-button"
            [nzType]="'primary'"
            [disabled]="registerForm.invalid || loading"
            (click)="registerBiller()"
            nzBlock
            [nzLoading]="loading"
          >
            Register<i nz-icon nzType="login" nzTheme="outline"></i>
          </button>
          <div nz-row>
            <a
              class="login-form-forgot"
              class="login-form-forgot"
              routerLink="/login/terms"
              >Terms of Service</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
