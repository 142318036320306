<!-- <div class="container">
  <div nz-row nzGutter="16" class="toolbar">
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="logo">
        <img src="assets/images/Group 1633.png" alt="logo" (click)="toHomePage()" />
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="8">
          <div class="title">
            <h2 class="text-title">E-biller</h2>
          </div>
        </div>
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="gutter-box" nz-row nzJustify="end">
        <div nz-col nzSpan="12"></div>
        <div nz-col nzSpan="12" class="home-wrp">
          <button nz-button nzType="default" class="home-btn" (click)="toHomePage()">
            Home
            <i nz-icon nzType="home" nzTheme="outline"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="bluescreen">
    <div id="particles-js"></div>
    <div class="loginscreen shadow">
      <div class="title-login">
        <span>Forgot your Password.</span>
      </div>

      <div class="login-container">
        <form nz-form [formGroup]="smsResetForm" class="login-form" autocomplete="off">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please enter your phone number">

              <input type="text" class="input-size" style="width:53vh;" nz-input placeholder="e.g 07XX XXX XXX"
                formControlName="phonenumber" ng2TelInput [ng2TelInputOptions]="{ initialCountry: 'ke' }" [ngModel]="phoneNo"
                (keyup)="getNumber()" (hasError)="hasError($event)" maxlength="15" />


            </nz-form-control>
            <ng-template #phoneNumberErrorTpl let-control>
              <ng-container  *ngIf="f.phonenumber.hasError('required') && f.phone.dirty">
                Please enter phone number!
              </ng-container>
            </ng-template>
            <ng-template #phoneNumberErrorTpl let-control>
              <ng-container *ngIf="f.phonenumber.hasError('pattern') && f.phone.dirty">
                Phone Number must be a valid
                phone number <br>
                E.g 07XXXXXXXX
              </ng-container>
            </ng-template>

          </nz-form-item>

          <button nz-button class="login-form-button" [nzType]="'primary'" [disabled]="smsResetForm.invalid || loading"
            (click)="emailReset()" nzBlock [nzLoading]="loading">
            Send SMS<i nz-icon nzType="login" nzTheme="outline"></i>
          </button>
          <div nz-row>
            <a class="login-form-forgot" class="login-form-forgot" routerLink="/login">Back to Login!</a>

          </div>
        </form>
      </div>
    </div>
  </div>

</div> -->


<div class="bluescreen">
  <div id="particles-js" class="particle-class"></div>
  <div nz-row nzType="flex" class="header-class"  [nzGutter]="16"  *ngIf="this.scrWidth >  678">
    <div nz-col nzSpan="8">
      <img
        class="stanbic-logo "
        src="assets/images/Group 1633.png"
        alt="logo"
        (click)="toHomePage()"
      />
    </div>
    <div nz-col nzSpan="8" style="height: 20vh" nzJustify="center">
      <h2
      class="ebiller-heading "
      >
        E-biller
      </h2>
    </div>
    <div
      nz-col
      nzSpan="8"
      class="home-wrp"
      nzJustify="end"
    >
      <button
        nz-button
        nzType="default"
        class="home-btn"
        (click)="toHomePage()"
      >
        Home
        <i nz-icon nzType="home" nzTheme="outline"></i>
      </button>
    </div>
  </div>

  <div nz-row nzJustify="center" class="ebiller-form ">

    <div class="actual-form" >
      <form
        autocomplete="off"
        nz-form
        [formGroup]="smsResetForm"
        class="login-form"
        (ngSubmit)="emailReset()"
      >
      <div nz-col nzSpan="8" *ngIf="this.scrWidth <  678">
        <img
        style=" padding-bottom:25px;"
          height="80"
          src="assets/images/logo.png"
          alt="logo"
          (click)="toHomePage()"
        />
      </div>
      <div nz-col style="height: 10vh" nzJustify="center" *ngIf="this.scrWidth <  678">
        <h2
       style="color: black; font-weight: 300; font-size: 4em; padding-bottom: 15px; text-align: center;  font-family: 'BentonSansProRegular';"
        >
          E-biller
        </h2>
      </div>
      <div
      style="color: black; font-size: 2em; padding-bottom: 15px; text-align: center;"
      >
        <h5 style="font-weight: bold;">Forgot your Password!</h5>

      </div>
      <nz-form-item *ngIf="this.scrWidth <  678">
        <nz-form-control nzErrorTip="Please enter your phone number">

          <input type="text" class="input-size" style="width:36vh;" nz-input placeholder="e.g 07XX XXX XXX"
            formControlName="phonenumber" ng2TelInput [ng2TelInputOptions]="{ initialCountry: 'ke' }" [ngModel]="phoneNo"
            (keyup)="getNumber()" (hasError)="hasError($event)" maxlength="15" />


        </nz-form-control>
        <ng-template #phoneNumberErrorTpl let-control>
          <ng-container  *ngIf="f.phonenumber.hasError('required') && f.phone.dirty">
            Please enter phone number!
          </ng-container>
        </ng-template>
        <ng-template #phoneNumberErrorTpl let-control>
          <ng-container *ngIf="f.phonenumber.hasError('pattern') && f.phone.dirty">
            Phone Number must be a valid
            phone number <br>
            E.g 07XXXXXXXX
          </ng-container>
        </ng-template>

      </nz-form-item>
      <nz-form-item *ngIf="this.scrWidth >  678">
        <nz-form-control nzErrorTip="Please enter your phone number">

          <input type="text" class="input-size" style="width:50vh;" nz-input placeholder="e.g 07XX XXX XXX"
            formControlName="phonenumber" ng2TelInput [ng2TelInputOptions]="{ initialCountry: 'ke' }" [ngModel]="phoneNo"
            (keyup)="getNumber()" (hasError)="hasError($event)" maxlength="15" />


        </nz-form-control>
        <ng-template #phoneNumberErrorTpl let-control>
          <ng-container  *ngIf="f.phonenumber.hasError('required') && f.phone.dirty">
            Please enter phone number!
          </ng-container>
        </ng-template>
        <ng-template #phoneNumberErrorTpl let-control>
          <ng-container *ngIf="f.phonenumber.hasError('pattern') && f.phone.dirty">
            Phone Number must be a valid
            phone number <br>
            E.g 07XXXXXXXX
          </ng-container>
        </ng-template>

      </nz-form-item>
      <button nz-button class="login-form-button" [nzType]="'primary'" [disabled]="smsResetForm.invalid || loading"
      (click)="emailReset()" nzBlock [nzLoading]="loading">
      Send SMS<i nz-icon nzType="login" nzTheme="outline"></i>
      </button>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="16" >
            <a
            class="login-form-forgot"
            class="login-form-forgot"
            routerLink="/login"
            >Back to Login</a
          >          </div>

        </div>

      </form>
    </div>

    <!-- <div id="particles-js"></div> -->
  </div>
</div>
