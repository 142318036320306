import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../payer/service/login.service';
import { ParticlesConfig } from 'particles-config';
import { AliasService } from '../../payer/service/alias.service';
import { BillerIdService } from '../../payer/service/billerId.service';
import { BillerTypeService } from '../../payer/service/billerType.service';
import { NotifyService } from '../../payer/service/notify.service';
import { UserProfileService } from '../../payer/service/user-profile.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { ViewBillersComponent } from '../../payer/misc/view-billers/view-billers.component';

declare var particlesJS: any;

@Component({
  selector: 'app-otp-login',
  templateUrl: './otp-login.component.html',
  styleUrls: ['./otp-login.component.css']
})


export class OtpLoginComponent implements OnInit {
  loading = false;
  msgs = [];


  myStyle: object = {};
  myParams: object = {};
  width = 100;
  height = 100;

  
  billerId = '';
  alias = '';
  notify = '';
  closed_biller_type = '';
  closed_biller_class = '';

 
  scrHeight:any;
  scrWidth:any;
  teamMenu = [];
  adminMenu = [];
  allowedMenus = [];
  allowedActions = [];
  menuRights = [];
  fileName = '';
  checked = true;

  smsCodeResetForm = new FormGroup({
    smscode: new FormControl('')
  });

  viewBillers = new ViewBillersComponent(this.router,
    this.loginservice, this.aliasService, this.notifyService, this.billerIdService, this.profileService);

 secretKey = 'hjfdhf$34556029hjfuur%)8839399qkksklk(()q8wj&*9w9kemmm8q8q0nm372901928';

  constructor(
    private loginservice: LoginService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router,
    private notification: NzNotificationService,
    private billerTypeService: BillerTypeService,
    private aliasService: AliasService,
    private notifyService: NotifyService,
    private billerIdService: BillerIdService,
    public profileService: UserProfileService
  ) { }

  ngOnInit(): void {
    this.invokeParticles();
    this.billerIdService.castBillerId.subscribe(billerId => this.billerId = billerId);
    this.aliasService.castAlias.subscribe(alias => this.alias = alias);
    this.notifyService.castNotify.subscribe(notify => this.notify = notify);

    this.billerTypeService.castClosedBillerClass.subscribe(closed_biller_class => this.closed_biller_class = closed_biller_class);
    this.smsCodeResetForm = this.fb.group({
      smscode: ['', [Validators.required]]
    });

    this.getScreenSize();
  }



  public invokeParticles(): void {
    particlesJS('particles-js', ParticlesConfig, function () { });
  }

  verifyOtpCode() {
    if (this.smsCodeResetForm.valid) {
      const formdata = this.smsCodeResetForm.value;
      const payload  = {
        key: formdata.smscode,
        email: sessionStorage.getItem('otp_email_address')

      };

      this.loading = true;
      this.loginservice.verifyLoginOtpCode(payload).subscribe(
        (response: any) => {
          this.loading = false;

          sessionStorage.setItem('closed_biller_type', response.body?.closed_biller_type);

          sessionStorage.setItem(
            'h-token',
            response.headers.get('Authorization')
          );
          sessionStorage.setItem('company_name', response.body?.company_name);
          sessionStorage.setItem('user-type', response.body?.user_type);
          sessionStorage.setItem('loginvalue', response.body?.loginvalue);


          if (response.body.messageCode === '02') {
            this.loading = false;
            return this.toastr.warning(response.body.message, 'Warning');
          }

          if (response.body.messageCode === '05') {
            this.loading = false;
            return this.toastr.warning(response.body.message, 'Warning');
          }
          if (response.body.messageCode === '06') {
            this.loading = false;

            this.toastr.warning(response.body.message);
            sessionStorage.setItem('phoneNumber',response.body.phone);

          } else if (response.body.messageCode === '09') {
            this.loading = false;

            this.toastr.warning(response.body.message);

            this.router.navigate(['/login/email-reset']);

          }

          if (
            response.body.messageCode == '00' ||
            response.body.messageCode == '12'
          ) {
            this.loginservice.tokenGlobal = response.body.token;
            this.loginservice.userType = response.body.user_type;



            sessionStorage.setItem('name_group', response.body.group_name);


            sessionStorage.setItem('team-policyType',JSON.stringify(response.body.team_policy_type));

            this.loginservice.companyCode = response.body.companyCode;
            this.loginservice.phoneNumber = response.body.biller_phone;

            sessionStorage.setItem('comp_code', response.body.companyCode);
            this.teamMenu = response.body.menus;
            this.adminMenu = response.body.adminMenu;
            if (response.body.adminMenu) {
              response.body.adminMenu.forEach(value => {
                this.allowedMenus.push(value.menuName);
                sessionStorage.setItem(
                  'access',
                  JSON.stringify(this.allowedMenus)
                );
              });
            }

            // response.menus.forEach((value:any) => {
            //   this.menuRights.push(value);
            //   sessionStorage.setItem(
            //     'menuRights',
            //     JSON.stringify(this.menuRights)
            //   );
            //   this.allowedActions.push(value);
            //   sessionStorage.setItem(
            //     'actions',
            //     JSON.stringify(this.allowedActions)
            //   );
            // });
            if (response.body.menus) {
              response.body.menus.forEach(value => {
                this.allowedMenus.push(value.menuName);
                sessionStorage.setItem(
                  'access',
                  JSON.stringify(this.allowedMenus)
                );

                this.menuRights.push(value);
                sessionStorage.setItem(
                  'menuRights',
                  JSON.stringify(this.menuRights)
                );
              });
              this.teamMenu.forEach((value: any) => {
                if (value.menuName === 'DashBoard') {
                  sessionStorage.setItem('DashBoard', value.menuName);
                } else if (value.menuName === 'My Payers') {
                  sessionStorage.setItem('My_Payers', value.menuName);
                } else if (value.menuName === 'maintain Account(s)') {
                  sessionStorage.setItem('My_Account', value.menuName);
                } else if (value.menuName === 'Invoices') {
                  sessionStorage.setItem('Invoices', value.menuName);
                } else if (value.menuName === 'Eslips Generated') {
                  sessionStorage.setItem('eslips', value.menuName);
                } else if (value.menuName === 'Reports') {
                  sessionStorage.setItem('Reports', value.menuName);
                } else if (value.menuName === 'My Team') {
                  sessionStorage.setItem('My_Team', value.menuName);
                } else if (value.menuName === 'Eslip(s)') {
                  sessionStorage.setItem('Eslip', value.menuName);
                } else if (value.menuName === 'Exceptions') {
                  sessionStorage.setItem('exceptions', value.menuName);
                }
              });
            }

            if (response.body.adminMenu) {
              this.adminMenu.forEach((value: any) => {
                if (value.menuName === 'DashBoard') {
                  sessionStorage.setItem('DashBoard', value.menuName);
                } else if (value.menuName === 'My Payers') {
                  sessionStorage.setItem('My_Payers', value.menuName);
                } else if (value.menuName == 'maintain Account(s)') {
                  sessionStorage.setItem('My_Account', value.menuName);
                } else if (value.menuName == 'Invoices') {
                  sessionStorage.setItem('Invoices', value.menuName);
                } else if (value.menuName == 'Eslips Generated') {
                  sessionStorage.setItem('eslips', value.menuName);
                } else if (value.menuName == 'Reports') {
                  sessionStorage.setItem('Reports', value.menuName);
                } else if (value.menuName == 'My Team') {
                  sessionStorage.setItem('My_Team', value.menuName);
                } else if (value.menuName == 'Eslip(s)') {
                  sessionStorage.setItem('Eslip', value.menuName);
                } else if (value.menuName == 'Policies') {
                  sessionStorage.setItem('Policies', value.menuName);
                }
              });
            }
          }

          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '0' &&
            response.body.user_type == 'biller' &&
            response.body.team_policy_type.length > 0 &&
            response.body.menus.length > 0
          ) {
            return this.router
              .navigate(['/biller/dashboard/welcome-screen'])
              .then(() => {
                sessionStorage.setItem(
                  'biller-type',
                  response.body.biller_type
                );
                this.toastr.success(response.body.message, 'Success');
                this.loading = false;
              });
          }

          if (response.body.messageCode == '12') {
            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.warning(response.body.message, 'Warning');
              this.loading = false;
            });
          }


          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '0' &&
            response.body.user_type == 'biller' &&
            response.body.closed_biller_type == 'B' &&
            response.body.menus.length > 0
          ) {

            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.success(response.body.message, 'Success');
              this.loading = false;
            });
          }

          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '0' &&
            response.body.user_type == 'biller' &&
            response.body.biller_type == 'Open' &&
            response.body.menus.length > 0
          ) {

            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.success(response.body.message, 'Success');
              this.loading = false;
            });
          }

          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '1' &&
            response.body.user_type == 'biller' &&
            response.body.biller_type == 'Open' &&
            response.body.menus.length > 0
          ) {

            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.success(response.body.message, 'Success');
              this.loading = false;
            });
          }

          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '1' &&
            response.body.user_type == 'biller' &&
            response.body.closed_biller_type == 'B' &&
            response.body.menus.length > 0
          ) {

            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.success(response.body.message, 'Success');
              this.loading = false;
            });
          }

          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '1' &&
            response.body.user_type == 'biller' &&
            response.body.closed_biller_type == 'C' &&
            response.body.menus.length > 0
          ) {
            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.success(response.body.message, 'Success');
              this.loading = false;
            });
          }

          if (
            response.body.messageCode == '00' &&
            response.body.loginvalue == '1' &&
            response.body.user_type == 'biller' &&
            response.body.closed_biller_type == 'A' &&
            response.body.team_policy_type.length > 0 &&
            response.body.menus.length > 0
          ) {
            sessionStorage.setItem('biller-type', response.body.biller_type);
            return this.router.navigate(['/biller/dashboard']).then(() => {
              this.toastr.success(response.body.message, 'Success');
              this.loading = false;
            });
          }

          if(  response.body.closed_biller_type == 'A' &&
               response.body.team_policy_type.length <=0 ){
            this.toastr.warning('Please contact admin to assign you policy type.!')

            this.loading = false ;

          } else {

          }

          if (
            response.body.messageCode == '00' &&
            response.body.messageCode == '00' &&
            response.body.user_type == 'payer'
          ) {

            if (this.billerId === '' || this.alias === '' || this.notify === '') {
              return this.router.navigate(['/payer/view-billers']).then(() => {
                this.toastr.success(response.body.message, 'Success');
                this.loading = false;
              });
            } else {

              this.viewBillers.billerPage(this.billerId, this.alias, this.notify, this.closed_biller_class);
            }


          }

          if (
            response.body.messageCode == '00' &&
            response.body.user_type == 'payer'
          ) {

            if (this.billerId === '' || this.alias === '' || this.notify === '') {
              return this.router.navigate(['/payer/view-billers']).then(() => {
                this.toastr.success(response.body.message, 'Success');
                this.loading = false;
              });
            } else {

              this.viewBillers.billerPage(this.billerId, this.alias, this.notify, this.closed_biller_class);
            }


          }

          if (response.body.messageCode === '02') {
            this.loading = false;
            return this.toastr.warning(response.body.message, 'Warning');
          }

          if (response.body.messageCode === '05') {
            this.loading = false;
            return this.toastr.warning(response.body.message, 'Warning');
          }
          if (response.body.messageCode === '06') {
            this.loading = false;

            this.toastr.warning(response.body.message);


          } else if (response.body.messageCode === '09') {
            this.loading = false;

            this.toastr.warning(response.body.message);

            this.router.navigate(['/login/email-reset']);

          }
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error('There is no server connection !');
        }
      );
    }
  }

  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
      }

  public hasError = (controlName: string, errorName: string) => {
    return this.smsCodeResetForm.controls[controlName].hasError(errorName);
  }

  toHomePage() {
    this.router.navigate(['/']);
  }
}
