<div class="bluescreen">
  <div id="particles-js" class="particle-class"></div>
  <div nz-row nzType="flex" class="header-class"  [nzGutter]="16"  *ngIf="this.scrWidth >  678">
    <div nz-col nzSpan="8">
      <img
        class="stanbic-logo "
        src="assets/images/Group 1633.png"
        alt="logo"
        (click)="toHomePage()"
      />
    </div>
    <div nz-col nzSpan="8" style="height: 20vh" nzJustify="center">
      <h2
      class="ebiller-heading "
      >
        E-biller
      </h2>
    </div>
    <div
      nz-col
      nzSpan="8"
      class="home-wrp"
      nzJustify="end"
    >
      <button
        nz-button
        nzType="default"
        class="home-btn"
        (click)="toHomePage()"
      >
        Home
        <i nz-icon nzType="home" nzTheme="outline"></i>
      </button>
    </div>
  </div>

  <div nz-row nzJustify="center" class="ebiller-form ">

    <div class="actual-form" >
      <form
      autocomplete="off"
        nz-form
        [formGroup]="loginForm"
        class="login-form"
        (ngSubmit)="loginBiller()"

      >
      <div nz-col nzSpan="8" *ngIf="this.scrWidth <  678">
        <img
        style=" padding-bottom:25px;"
          height="80"
          src="assets/images/logo.png"
          alt="logo"
          (click)="toHomePage()"
        />
      </div>
      <div nz-col style="height: 10vh" nzJustify="center" *ngIf="this.scrWidth <  678">
        <h2
       style="color: black; font-weight: 300; font-size: 4em; padding-bottom: 15px; text-align: center;  font-family: 'BentonSansProRegular';"
        >
          E-biller
        </h2>
      </div>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your username!">
            <nz-input-group nzPrefixIcon="user">
              <input
                type="text"
                nz-input
                formControlName="email"
                placeholder="email"
                (keydown.enter)="loginBiller()"
                style="width: 100%"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Password!">
            <nz-input-group nzPrefixIcon="lock">
              <input
                type="password"
                nz-input
                formControlName="password"
                placeholder="Password"
              />
            </nz-input-group>
          </nz-form-control>
        </nz-form-item>
        <button
          nz-button
          class="login-form-button login-form-margin"
          [disabled]="loginForm.invalid || loading"
          [nzType]="'primary'"
        >
          Log in
        </button>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="16">
            <a
              class="login-form-forgot"
              class="login-form-forgot"
              (click)="forgotPassword()"
              >Forgot password</a>

          </div>

        </div>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="16" >
            <a
            class="login-form-forgot"
            class="login-form-forgot"
            routerLink="/login/terms"
            >Terms of Service</a
          >          </div>

        </div>

      </form>
    </div>

    <!-- <div id="particles-js"></div> -->
  </div>
</div>
