import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../payer/service/login.service';
import {  EmailReset } from '../../payer/service/login.model';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ParticlesConfig } from 'particles-config';

declare var particlesJS: any;
declare const indexPath: any;

@Component({
  selector: 'app-email-reset',
  templateUrl: './email-reset.component.html',
  styleUrls: ['./email-reset.component.scss']
})

export class EmailResetComponent implements OnInit {

  userEmail = sessionStorage.getItem('userEmail');
  scrHeight:any;
  scrWidth:any;


  constructor(
    private loginservice: LoginService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}
  loading = false;
  myStyle: object = {};
  myParams: object = {};
  width = 100;
  height = 100;
  emailResetForm = new FormGroup({
    email: new FormControl('')
  });

  ngOnInit() {
    console.log(this.userEmail)
    this.invokeParticles();
    this.emailResetForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.getScreenSize();

  }


  public invokeParticles(): void {
    particlesJS('particles-js', ParticlesConfig, function () { });
  }

  //Get the wdth of the current screen
  getScreenSize(event?) {
  this.scrHeight = window.innerHeight;
  this.scrWidth = window.innerWidth;
    }


  emailReset() {
    if (this.emailResetForm.valid) {
      const formdata = this.emailResetForm.value;
      const payload: EmailReset = {
        email: formdata.email
      };
      this.loading = true;
      this.loginservice.emailReset(payload).subscribe(
        (response: any) => {
          this.loading = false;
          if (response.messageCode === '00') {
            this.toastr.success(response.message, 'Success');
          } else if (response.messageCode === '02') {
            this.toastr.warning(response.message, 'Warning');
          } else if (response.messageCode === '06') {
            this.toastr.warning(response.message, 'Warning');
          } else if (response.messageCode === '07') {
            this.toastr.warning(response.message, 'Warning');
          }
        },
      );
    }
  }

  toHomePage() {
    location.href = indexPath;
  }
  toLoginPage() {
    this.router.navigate(['/login']);
  }

  registerBiller() {
    this.router.navigate(['biller/register']);
  }
}
