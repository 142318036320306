<!-- <mat-card class="example-card">
  <mat-card-header>
    <mat-card-title color="accent">
      <mat-icon>error</mat-icon> Sorry action was unsuccessful
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="" fxLayout="column">
      <div fxLayout="row">
        <div fxFlex="100">
          <p>
            {{ alertService.ALERT_MESSAGE }}
          </p>
        </div>
      </div>

      <div fxLayout="row">
        <div fxFlex="100">
          <button mat-raised-button color="primary" (click)="closeAlert()">
            OKAY
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card> -->

<div nz-row>
  {{ alertService.ALERT_MESSAGE }}
</div>
