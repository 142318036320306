import { payerAccounts } from './../../payer/service/login.model';
import { Component, OnInit, ɵConsole, ViewChild, ElementRef } from '@angular/core';
import { LoginService } from '../../payer/service/login.service';

import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { NzNotificationService } from 'ng-zorro-antd';
import { AliasService } from '../../payer/service/alias.service';
import { NotifyService } from '../../payer/service/notify.service';
import { BillerIdService } from '../../payer/service/billerId.service';
import { UserProfileService } from '../../payer/service/user-profile.service';
import { ParticlesConfig } from '../../../../../../particles-config';
import { BillerTypeService } from '../../payer/service/billerType.service';
import { ViewBillersComponent } from '../../payer/misc/view-billers/view-billers.component';

declare const indexPath: any;
declare let particlesJS: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  constructor(
    private loginservice: LoginService,
    private fb: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private notification: NzNotificationService,
    private billerTypeService: BillerTypeService,
    private aliasService: AliasService,
    private notifyService: NotifyService,
    private billerIdService: BillerIdService,
    public profileService: UserProfileService
  ) { }

  @ViewChild('myDOMElement', { static: true }) MyDOMElement: ElementRef<any>;
  loading = false;
  billerId = '';
  alias = '';
  notify = '';
  closed_biller_type = '';
  closed_biller_class = '';

  myStyle: object = {};
  myParams: object = {};
  width = 100;
  height = 100;
  scrHeight:any;
  scrWidth:any;
  teamMenu = [];
  adminMenu = [];
  allowedMenus = [];
  allowedActions = [];
  menuRights = [];
  fileName = '';
  checked = true;

  viewBillers = new ViewBillersComponent(this.router,
     this.loginservice, this.aliasService, this.notifyService, this.billerIdService, this.profileService);

  secretKey = 'hjfdhf$34556029hjfuur%)8839399qkksklk(()q8wj&*9w9kemmm8q8q0nm372901928';

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  ngOnInit() {


    this.invokeParticles();
    this.billerIdService.castBillerId.subscribe(billerId => this.billerId = billerId);
    this.aliasService.castAlias.subscribe(alias => this.alias = alias);
    this.notifyService.castNotify.subscribe(notify => this.notify = notify);

    this.billerTypeService.castClosedBillerClass.subscribe(closed_biller_class => this.closed_biller_class = closed_biller_class);
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
    this.getScreenSize();
  }

  public invokeParticles(): void {
    particlesJS('particles-js', ParticlesConfig, function () { });
  }

    //Get the wdth of the current screen
    getScreenSize(event?) {
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
        }


  loginBiller() {

    if (this.loginForm.valid) {

      const formdata = this.loginForm.value;

      this.fileName = btoa(formdata.password);

      const payload = {
        email: formdata.email,
        password: this.fileName
      };


      this.loading = true;
      this.loginservice.loginBiller(payload).subscribe(
        (response: any) => {

          this.loading = false;

          if (response.body.messageCode === '02') {
            this.loading = false;
            return this.toastr.warning(response.body.message, 'Warning');
          }

          if (response.body.messageCode === '05') {
            this.loading = false;
            return this.toastr.warning(response.body.message, 'Warning');
          }
          if (response.body.messageCode === '06') {
            this.loading = false;

            this.toastr.warning(response.body.message);
            sessionStorage.setItem('phoneNumber',response.body.phone);

          } else if (response.body.messageCode === '09') {
            this.loading = false;

            this.toastr.warning(response.body.message);

            this.router.navigate(['/login/email-reset']);

          }

          if (
            response.body.messageCode == '00' ||
            response.body.messageCode == '12'
          ){
            this.router.navigate(['/login/otp']);
            sessionStorage.setItem('otp_email_address', formdata.email);
          }


          
        },
        (err: any) => {
          this.loading = false;
          this.toastr.error('There is no server connection !');
        }
      );
    }
  }

  toHomePage() {
    location.href = indexPath;
  }
  toRegister() {
    this.router.navigate(['login/register']);
  }

  registerBiller() {
    this.router.navigate(['biller/register']);
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.loginForm.controls[controlName].hasError(errorName);
  }

  accessedMenus() {
    this.adminMenu.forEach(value => { });
  }

  testEnter(event) { }

  showPassword(){


    let  x = this.MyDOMElement.nativeElement;
    if (this.checked == true) {

      x.type = "text";

    } else {
      x.type = "password";
    }
  }

  forgotPassword(){
    const formdata = this.loginForm.value;
    const payload = {
      email: formdata.email,
      password: this.fileName
    };
    sessionStorage.setItem('userEmail',payload.email);
    this.router.navigate(['login/email-reset']);
  }
}
