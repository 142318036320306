<div class="faq-container">
    <div>
      <h1 class="faq-title">E-BILLER TERMS AND CONDITIONS</h1>
      <button       
        pButton
        type="button"
        label="Back"
        icon="pi pi-chevron-left"
        class="ui-button-secondary ui-button-raised close-btn button-back"
        (click)="closeSidebar()"
      ></button>
    </div>
  
      <div class="faq-body">
        <h4 class="title">
            1. Introduction: About these terms
        </h4>
        <div>
          <ul  class = "list-style-alpha">
              <li>
                  The Terms and Conditions for E-Biller Portal ("these Terms") take effect (begin) when you register for E-Biller.)
                  </li>
                  <li>
                 We may change these Terms from time to time. The latest version of the Terms applies to you each time you use E-Biller.
                  </li>
                  <li>
                    You must know, understand and comply with the Product and general terms that applies to the user and your organisation’s user profile.
                  </li>
                  <li>
                    The Terms are a binding legal agreement between yourself and Stanbic Bank.
                  </li>
                   <li>
                   Please pay special attention to the highlighted sections. These are important clauses which explain what may limit our responsibility or involve some risk for you.
                   </li>
          </ul>   
            
        </div>
  
        <h4 class="title">
          2. Definitions .
        </h4>
          
        <div>
        We have defined some of the words used in this document. These words begin with a capital letter. Singular words include the plural and the other way <br>
            <span class="span-class">"Access Codes"</span>   means any of the secret characters (letters, numbers and special characters) you use for system access including passwords and user names. <br>

             <span class="span-class">"The Bank, we or us" </span> means Stanbic Bank Limited (Registration Number XXXXX) and its successors or assigns. <br>
              
            <span class="span-class"> "Communication" </span> means both the Device and the Medium, as defined below.   <br>
              
            <span class="span-class">"Communication System"</span>  means a way of transferring information from one system to another <br>
              
            <span class="span-class">"Corporate Pay"</span>  means online portals powered by Stanbic Bank (K) Limited in collaboration with Kenya Power Company for Kenya Power clients to access and maintain meter accounts; generate electronic slips; and manage billing cycle records. <br>
              
            <span class="span-class"> "Device"</span>    means the equipment you use to access E-Biller Portal, for example, a computer, cell phone, smartphone, tablet, telephone, smart television or similar technology. <br>
              
            <span class="span-class">"E-slip" </span> means the electronic slip generated by a user for the purposes of facilitating power payments. The e-slip is a self-sufficient payment slip with unique system generated e-slip number and Kenya Power bill payment account details. <br>
              
            <span class="span-class"> "General Terms"</span>  means the general terms and conditions for access and use of E-Biller Portal as defined and stipulated in these Terms. <br>
              
            <span class="span-class"> "Group"</span>  means Standard Bank Group, the holding company and any subsidiary of the holding company and all of its subsidiaries. <br>
              
            <span class="span-class"> "Intellectual"</span>  means all rights in and to Intellectual Property.<br>
            <span class="span-class">"Intellectual Property"</span>  means all:
               <ul class=list-style-alpha>
                 <li>
                    inventions, specifications, patents, designs, trademarks, service marks, trade names and all goodwill associated with these;
                 </li>
                 <li>
                    copyright, including copyright in logos, devices, designs, multimedia works and computer software programs (in source and object code form), as well as programmers’ or developers’ notes, flow charts and design documents;
                 </li>
                 <li>
                    rights protecting goodwill and reputation;
                 </li>
                 <li>
                    proprietary material, know-how, ideas, concepts, trade secrets, methods, techniques, graphics;
                 </li>
                 <li>
                    schematics;
                 </li>
                 <li>
                    marketing;
                 </li>
                 <li>
                    sales and user data;
                 </li>
                 <li>
                    domain names and URLs;
                 </li>
                 <li>
                    databases and rights in databases
                 </li>
                 <li>
                    confidential information;
                 </li>
                 <li>
                    other intellectual property rights and similar kinds of protection that are registered or can be registered anywhere in the world; and
                 </li>
                 <li>
                    applications for, and rights to apply for, the protection of any of the items on this list.
                 </li>
              </ul>
            
              <span class="span-class">"Kenya Power Company"</span>means the power service provider. <br>
              <span class="span-class">"Medium"</span> means the way you access E-Biller, for example, Internet, Wireless Application Protocol (WAP), Wireless Internet Gateway (WIG), SMS and voice (for example, an automated voice recognition system or similar technology).<br>
              <span class="span-class">  "Meter Accounts"</span> means any Kenya Power Company meter account created on your organisation’s user profile and successfully validated with Kenya Power records and validated by you as one of your existing Kenya Power meter account <br>
              <span class="span-class"> "Organisation User Profile"</span> means the digital identity created by an organisation when it registers for E-Biller .<br>
              <span class="span-class"> "Password" </span>means the customer-selected password for system access.<br>
              <span class="span-class"> "Personal Information" </span>means information about an identifiable, natural or juristic person. This includes information about:
               <ul class="list-style-alpha">
                 <li>
                    race, nationality, ethnic or social origin;
                 </li>
                 <li>
                    gender or sexual orientation;
                 </li>
                 <li>
                    age;
                 </li>
                 <li>
                    marital status;
                 </li>
                 <li>
                    physical or mental health;
                 </li>
                 <li>
                    disability;
                 </li>
                 <li>
                    religion or belief;
                 </li>
                 <li>
                    language;
                 </li>
                 <li>
                    birth date;
                 </li>
                 <li>
                    education;
                 </li>
                 <li>
                    identity number;
                 </li>
                 <li>
                    telephone number;
                 </li>
                 <li>
                    email, postal or street address;
                 </li>
                 <li>
                    financial, criminal or employment history; and
                 </li>
                 <li>
                    Private correspondence sent by that person, in any form.
                 </li>
               </ul>
               <span class="span-class">"Process"</span> means an operation or activity, which could be automated, that affects Personal Information. Processes and processing include:
               <ul class ="list-style-alpha">
                 <li>
                    collecting;
                 </li>
                 <li>
                    receiving;
                 </li>
                 <li>
                    recording;
                 </li>
                 <li>
                    organising;
                 </li>
                 <li>
                    storing;
                 </li>
                 <li>
                    updating or changing;
                 </li>
                 <li>
                    recovering;
                 </li>
                 <li>
                    using;
                 </li>
                 <li>
                    sending;
                 </li>
                 <li>
                    merging;
                 </li>
                 <li>
                    linking;
                 </li>
                 <li>
                    blocking;
                 </li>
                 <li>
                    erasing; or
                 </li>
                 <li>
                    Destroying information
                 </li>
               </ul>
               <span class="span-class">"ISP"</span> means an Internet service provider, which is a company that provides access to the Internet.<br>
               <span class="span-class">"SMS"</span> means Short Message Service.<br>
               <span class="span-class">"User E-mail ID"</span> means the email address you use to register and access various E-Biller services.<br>
               <span class="span-class"> "User name"</span> means the customer-selected name for system access<br>
               <span class="span-class">  "User Profile" </span> the digital identity created under the Organisation User Profile for each Kenya Power account.<br>
               <span class="span-class"> "User, you or your"</span> means the person or legal entity that registers for and uses E-Biller , or in whose name an Account is opened.
        </div>
  
        <h4 class="title">
          3. Registering for and using E-Biller Portal services
        </h4>
  
        <div>
            <ul  class = "list-style-alpha">
                <li>
                    Use your registered user name and password to access E-Biller.
                    </li>
                    <li>
                        Only the officers of your organisation and persons with permission from the organisation can access the Organisation User Profile you create for your organisation.
                      
                    </li>
                    <li>
                        Each Kenya Power Company account holder in your organisation will only be able to access its profile and functionalities and tasks associated with its profile class (type)
                    </li>
                   
            </ul>   
              
        </div>
        <h4 class="title">
            4. Post Paid Power Meter Account Services
        </h4>
  
        <div>
            <ul  class = "list-style-alpha">
                <li>
                    Post Paid Meter Account Services fall under the terms and conditions of Kenya Power Company. If you have any questions about your post-paid service, please go <br>directly to Kenya Power Company. We shall not be liable for any losses you may suffer because of any actions or omissions of Kenya Power Company.
                    </li>
                    <li>
                        Please visit Kenya Power Company website at http://www.kplc.co.ke/ or please call Kenya Power Contact Centre hotline numbers 95551 or 0703070707 or 0732170170 <br> for service-related enquiries.
                    </li>
            </ul>  
        </div>
        <h4 class="title">
           5. Fees
        </h4>
  
        <div>
          <p>
              There shall be no charge to you and or your organisation for using the E-Biller Portal and any of the resources therein.
          </p>
        </div>
        <h4 class="title">
          6.  Looking after your Access Codes
        </h4>
  
        <div>
            <ul  class = "list-style-alpha">
                <li>
                  You are permitted use the same Access Codes for all your E-Biller  or connected services.
                </li>
                <li>
                    All Access Codes must be kept secret and safe to prevent unauthorised access to your organisation’s account and any of the user profiles therein.
                </li>
                <li>
                    Do not keep your Access Codes on a computer or in any other place where it can easily be accessed by others.
                </li>
                <li>
                    Do not disclose your Access Codes to anybody, including colleagues, friends and family.
                </li>
                <li>
                    Contact our Customer Contact Centre immediately if any unauthorised person obtains or requests your Access Codes.
                </li>
                <li>
                    We are not liable for any loss or damage you may suffer if you fail to keep your Access Codes safe or give your Access Codes to any other person.
                </li>
                <li>
                    You may change any Access Code for your organisation as often as you may deem reasonable 
                </li>
                <li>
                    You may deactivate the Access Code (stop it from working) immediately, for your organisational users.
                </li>
            </ul>  
        </div>
  
        <h4 class="title">
          7.  Availability of E-Biller Portal
        </h4>
  
        <div>
            <ul  class = "list-style-alpha">
                <li>
                    E-Biller Portal may not always be available. There are many reasons for this, including but not limited to:
                    <ul class = "list-style-roman">
                        <li>
                            Technical failure or problems with a Communication System (ours or another one) directly or indirectly involved in providing E-Biller ;
                              
                        </li>
                        <li>
                            Problems with a telecommunication or electricity service; or other circumstances beyond our control.
                        </li>             
                    </ul>  
                      
                </li>
                <li>
                    If E-Biller  is unavailable for any reason, you agree to use any alternative channels and services that may be provided by the Kenya Power Company to pay any outstanding bills so that you limit potential service interruption.
                </li>             
            </ul>  
        </div>
        <h4 class="title">
          8.  Ending your E-Biller Portal session
        </h4>
  
        <div>
            <ul  class = "list-style-alpha">
                <li>
                    You must make sure that you log out E-Biller , once you have finished using E-Biller Portal.
                </li>
                <li>
                    We will not be liable for any loss or damage you may suffer in the event of unauthorised access to your account arising from your failure to log out of E-Biller Portal.
                </li>             
            </ul>  
        </div>
        <h4 class="title">
            9.  Information on our Communication System
          </h4>
    
          <div>
              <ul  class = "list-style-alpha">
                  <li>
                      Any information and content on our Communication System only educates you on the use of E-Biller and its resources.
                  </li>
                  <li>
                      Ensure you refresh your screen regularly to make sure that the information you see is the latest that is available on our Communication System.
                  </li>             
              </ul>  
          </div>
          <h4 class="title">
              10.  Links to third parties (other) Communication Systems.
            </h4>
      
            <div>
                <ul  class = "list-style-alpha">
                    <li>
                        Our Communication System may link to other Communication Systems that have information or content from other parties.
                    </li>
                    <li>
                        Although we try to only include links to trustworthy parties or systems, we do not give any guarantees regarding these websites, software or hardware, including guarantees of security or performance. 
                   </li>  
                   <li>
                      By connecting to another communication system, you waive (give up) any claim you may have against us for any loss or damage you may suffer as a result.
                  </li>
                  <li>
                      If we give a link to any other information, Communication System, person or business, it does not mean that we have checked or approve of them.
                  </li>              
                </ul>  
            </div>

            <h4 class="title">
                11. Security of the Communication System
              </h4>
        
              <div>
                  <ul  class = "list-style-alpha">
                      <li>
                          Information sent over a communication system can be intercepted (stopped), seen or changed unlawfully if a link is not secure. We continuously take steps to limit these risks. These steps are explained in the privacy and security statement on E-Biller and include checking any information you send using our Communication System.
                        </li>
                      <li>
                          You must follow all security tips published on E-Biller Portal from time to time.
                      </li>  
                     <li>
                        We are not responsible for any loss or damage you may suffer because someone gained access to the Communication System illegally or without permission.
                      </li>                                
                  </ul>  
              </div>

              <h4 class="title">
                  12. Software and hardware
                </h4>
          
                <div>
                    <ul  class = "list-style-alpha">
                        <li>
                            You must use hardware (computer equipment) and software (programs) suitable for E-Biller. If you do not, the Communication System may not work properly and this could increase your security risks.
                       </li>                            
                    </ul>  
                </div>

                <h4 class="title">
                    13. Our Intellectual Property
                  </h4>
            
                  <div>
                      <ul  class = "list-style-alpha">
                          <li>
                              The copyright and any other Intellectual Property rights in all content on or sent through the Communication System, (including storage media) belongs to us. You may print and make a paper copy of content sent through the Communication System, including logos, images or multimedia works only if:
                              <ul class = "list-style-roman">
                                  <li>
                                      it is for your organisation’s E-Biller  use;     
                                  </li>
                                  <li>
                                      It is not used for any commercial (business) reason; and
                                  </li>  
                                  <li>
                                      The copy of the content shows our copyright notice.
                                  </li>                  
                              </ul>  
                                
                          </li>
                          <li>
                              The logos and trademarks on our Communication System belong to us or the Group. Nothing on our Communication System gives any person the right to use any trademark or other Intellectual Property (our property) without our written permission.
                          </li> 
                          <li>
                              Even if any content on the Communication System is not confidential or there is no copyright in it, we own the content and you have no rights in it.
                          </li>
                          <li>
                              If you want to use content from the Communication System, please email CSB_LegalServicesDesk@mail.standardbank.com. If we do not reply in writing in five business days we have not agreed to let you use this content.
                          </li>               
                      </ul>  
                  </div>
                  <h4 class="title">
                      14. Using and sharing your Personal Information
                    </h4>
              
                    <div>
                        <ul  class = "list-style-alpha">
                            <li>
                                You agree that we may collect and process your Personal Information on E-Biller  so that we can:
                                <ul class = "list-style-roman">
                                    <li>
                                        open and manage your Profile and Account;    
                                    </li>
                                    <li>
                                        provide a combination of services,
                                    </li>  
                                    <li>
                                        analyse information to identify possible markets and trends, and develop new products and services.
                                    </li>                  
                                </ul>  
                                  
                            </li>
                            <li>
                                You agree that we may share your Personal Information with Kenya Power Company for purposes of facilitating and rendering services to you and updating your account records at Kenya Power Company.
                              </li> 
                            <li>
                                Even if any content on the Communication System is not confidential or there is no copyright in it, we own the content and you have no rights in it.
                            </li>
                            <li>
                                You accept that:
                                <ul class = "list-style-roman">
                                    <li>
                                        without your Personal Information we may be unable to register an account or continue to offer services to you; and    
                                    </li>
                                    <li>
                                        you are giving us your Personal Information voluntarily (willingly).
                                    </li>                                                     
                                </ul>  
                              
                            </li>               
                        </ul>  
                    </div>
                    <h4 class="title">
                        15. Marketing by post, email or text messages
                      </h4>
                
                      <div>
                          <ul  class = "list-style-alpha">
                              <li>
                                  We may use your Personal Information to contact you about our products, services, and special offers from Stanbic Bank Limited with your permission.                                
                                    
                              </li>
                              <li>
                                  We will do this by post, email, or text message.
                                </li> 
                              <li>
                                  You shall be able to unsubscribe from these services through Kenya Power customer service help desk.
                              </li>
                                         
                          </ul>  
                      </div>

                      <h4 class="title">
                          16. Warranties (promises) made by you
                        </h4>
                  
                        <div>
                          <p>You confirm that:</p>
                            <ul  class = "list-style-alpha">
                                <li>
                                    you are able to enter into a contract (you have full contractual capacity) and no court has declared you mentally unfit to do so;      
                                </li>
                                <li>
                                    you are not, and will not be, located in a country that is the subject of sanctions issued by:
                                    <ul  class = "list-style-alpha">
                                      <li>
                                          United Nations Security Council (UNSC)
                                      </li>
                                      <li>
                                          United States Office of Foreign Assets Control (OFAC)
                                      </li>
                                      <li>
                                          Her Majesty’s Treasury (HMT)
                                      </li>
                                      <li>
                                          European Union (EU)
                                      </li>
                                      <li>
                                          French Ministry of Economy, Finance and Industry (MINEFI)
                                      </li>
                                    </ul>
                                  </li> 
                                <li>
                                    you and your organisation are not listed in lists of terrorists or terrorist organisation that are issued from time to time by any of the above-named organisations.
                                </li>
                                <li>
                                    You have been told, in clauses , General provisions and Customer contact information below, that you can contact us with any questions you may have;
                                </li>
                               <li>
                                  All information given to us is true and accurate; and
                               </li>   
                               <li>
                                  You have read and understood these Terms before entering into this agreement.
                               </li>         
                            </ul>  
                        </div>

                        <h4 class="title">
                            17.Disclaimer and limitation of liability (limits our responsibility to you)
                          </h4>
                    
                          <div>                           
                              <ul  class = "list-style-alpha">
                                  <li>
                                      Your use of the Communication System and E-Biller Portal depends on factors beyond our control, such as the network coverage or availability of internet services. We are not liable for any loss or damages you may suffer if you cannot access E-Biller because of problems beyond our control.
                                    </li>
                                    <li>
                                        We have taken care to make sure that content on the Communication System is accurate and that you should not suffer any loss or damage by using it. However, information on the Communication System and E-Biller Portal is provided “as is” and we will not be responsible for any damages that may follow if you rely on it.

                                    </li>
                                    <li>
                                        We (our owners, employees, consultants, agents or any person connected to Stanbic Bank (K) Limited) are not responsible for any direct or indirect loss or damages related to your use of the Communication System or E-Biller , for any reason, even if we were told that loss or damage was possible. We are not responsible for any loss or damages caused by:
                                        <ul class = "list-style-alpha">
                                          <li>
                                              someone finding out any of your secret Access Codes (such as Password and or Username) and using these codes to register for E-Biller Portal and fraudulently accessing on your account;
                                            
                                          </li>
                                          <li>
                                              any technical or other problems (interruption, malfunction, downtime or other failures) which affect E-Biller , a Communication System, our banking system, a third-party system or any part of any database, for any reason;
                                          </li>
                                          <li>
                                              you relying on any information on the Communication System;
                                          </li>
                                          <li>
                                              any Organisational or Personal Information or other information being lost or damaged because of technical problems, power failures, unlawful acts (such as data theft), a harmful computer program or virus, or your own negligence (lack of care);
                                          </li>
                                          <li>
                                              any failure or problem affecting goods or services provided by any other party, for example, a telecommunication service provider, internet service providers, Kenya Power Company or a local or other authority; or
                                          </li>
                                          <li>
                                              any event that we have no direct control over.
                                          </li>
                                        </ul>
                                    </li>                                 
                              </ul>  
                          </div>

                          <h4 class="title">
                              18.Announcements about changes or updates to these Terms
                            </h4>
                      
                            <div>                             
                                <ul  class = "list-style-alpha">
                                    <li>
                                        We may make announcements about changes or updates to these Terms, or any of our services. We may do this on E-Biller Portal website, or by sending you an email, text message (SMS) or using another communication technology.
                                    </li>
                                    <li>
                                        If an announcement about E-Biller  has contractual (legally binding) terms relating to E-Biller, these new or updated terms will be seen as being included in these (original) Terms. By continuing to use E-Biller, you agree to these changes.
                                    </li> 
                                </ul>  
                            </div>
                            <h4 class="title">
                                19.What happens if you breach (go against) these Terms
                              </h4>
                        
                              <div>                             
                                  <ul  class = "list-style-alpha">
                                      <li>
                                          We may stop you from using E-Biller Portal if you do something that is against these Terms, and do not make it right within five days after we have asked you to do so. Even if you do make it right, we may still take steps against you, such as applying to a court for an interdict (ban) or other order against you.
                                        
                                      </li>
                                      <li>
                                          We may end our relationship with you and stop you from using E-Biller Portal by giving you written notice (Termination Notice). However, we do not have to give you notice and may close your Account immediately if we:
                                          <ul class="list-type-alpha">
                                            <li>
                                                believe or suspect your Account is being used wrongly or unlawfully (illegally); or
                                            </li>
                                            <li>
                                                Must do this for legal reasons.
                                            </li>
                                          </ul>
                                        </li> 
                                  </ul>  
                              </div>
                              <h4 class="title">
                                  20.How disagreements or differences will be resolved
                                </h4>
                          
                                <div>                             
                                    <ul  class = "list-style-alpha">
                                        <li>
                                            If you have a complaint about your use of E-Biller Portal or these Terms, please follow our complaints process, which is available at: www.stanbic.co.ke or send an email to customercare@stanbic.com
                                        </li>                                        
                                    </ul>  
                                </div>
                                <h4 class="title">
                                    21. Law governing our relationship
                                  </h4>
                            <p>Kenyan law will govern these Terms.</p>
                            <h4 class="title">
                                22.General provisions
                              </h4>
                        
                              <div>                             
                                  <ul  class = "list-style-alpha">
                                      <li>
                                          Headings in these Terms are only for information and may not be used to interpret these Terms.
                                      </li>  
                                      <li>
                                          East African time applies when working out any dates or times.
                                      </li>   
                                      <li>
                                          If any dispute results from technical issues related to a Communication System, a court or arbitrator will interpret these Terms practically without focusing too much on technical issues.
                                      </li> 
                                      <li>
                                          Any extension of time or other indulgence we may allow you will not affect any of our rights, whether the indulgence is express or implied. We do not waive (give up) any of our rights.
                                      </li>  
                                      <li>
                                          If any clause in these Terms is invalid or illegal or cannot be enforced, the other clauses will still be valid.
                                      </li>  
                                      <li>
                                          You are responsible for making sure that you never use E-Biller Portal for any illegal purpose. You will be legally responsible for any illegal transactions that you make.
                                      </li>  
                                      <li>
                                          If you have any questions or do not understand anything about these Terms, please call our Customer Contact Centre on +254711068000.
                                      </li>                            
                                  </ul>  
                              </div>

                              <h4 class="title">
                                  23.Customer contact information
                                </h4>
                          
                                <div>                             
                                    <ul  class = "list-style-alpha">
                                        <li>
                                            If you have any complaints or questions about E-Biller Portal, you can call us on +254711068000.
                                        </li>
                                        <li>
                                            If you have a problem and we do not solve it, or you are not happy with the way that it was solved, you may contact the Kenya Power Company:
                                            <ul class="list-type-alpha">
                                              <li>
                                                  telephone: 95551 or 0703070707 or 0732170170; or
                                              </li>
                                              <li>
                                                  website:<a href="http://www.kplc.co.ke/" class=""> http://www.kplc.co.ke/</a>                                                
                                              </li>
                                            </ul>
                                          </li> 
                                    </ul>  
                                </div>
                                 
      </div>

  </div>
  