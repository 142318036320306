import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'account-opening',
    loadChildren:
      () => import('./account-opening/account-opening.module').then(m => m.AccountOpeningModule)
  },
  {
    path: 'payer',
    loadChildren: () => import('./payer/payer.module').then(m => m.PayerModule)
  },
  {
    path: 'biller',
    loadChildren: () => import('./biller/biller.module').then(m => m.BillerModule)
  },
  {
    path: 'invoice',
    loadChildren: () => import('./biller/invoices/invoices.module').then(m => m.InvoicesModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./register-login/register-login.module').then(m => m.RegisterLoginModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error-handling/error-handling.module').then(m => m.ErrorsModule)
  },
  // { path: '404', component: ErrorPageComponent },
  // { path: 'access', component: AccessDeniedComponent },

  { path: '**', redirectTo: '/error/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
