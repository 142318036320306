
<div class="container">
    <div nz-row nzGutter="16" class="toolbar">
      <div nz-col class="gutter-row" nzSpan="8">
        <div class="logo">
          <img
            src="assets/images/Group 1633.png"
            alt="logo"
            (click)="toHomePage()"
          />
        </div>
      </div>
      <div nz-col class="gutter-row" nzSpan="8">
        <div nz-row nzJustify="center">
          <div nz-col nzSpan="8">
            <div class="title"><h2 class="text-title">E-biller</h2></div>
          </div>
        </div>
      </div>
      <div nz-col class="gutter-row" nzSpan="8">
        <div class="gutter-box" nz-row nzJustify="end">
          <div nz-col nzSpan="12"></div>
          <div nz-col nzSpan="12" class="home-wrp">
            <button
              nz-button
              nzType="default"
              class="home-btn"
              (click)="toHomePage()"
            >
              Home
              <i nz-icon nzType="home" nzTheme="outline"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bluescreen">

      <div id="particles-js"></div>
    <div class="loginscreen shadow">
      <div class="title-login">
        <span>Enter Your OTP Code.</span>
      </div>

      <div class="login-container">
        <form
        nz-form
        [formGroup]="smsCodeResetForm"
        class="login-form"
        autocomplete="off"
        (ngSubmit)="verifyOtpCode()"
      >
      <nz-form-item>
        <nz-form-control nzErrorTip="Please enter reset Code">
            <input  nz-input type="text" name="smscode" id="smscode" placeholder="Enter Your Code."  formControlName="smscode"
            type="text"
            maxlength="50"
            id="smscode">
        </nz-form-control>
      </nz-form-item>

      <button
      nz-button
      class="login-form-button"
      [nzType]="'primary'"
      [disabled]="smsCodeResetForm.invalid || loading"
      (click)="verifyOtpCode()"
      nzBlock
      [nzLoading]="loading"
    >
      Submit Code<i nz-icon nzType="login" nzTheme="outline"></i>
    </button>

    <div nz-row>
      <a
        class="login-form-forgot"
        class="login-form-forgot"
        routerLink="/login/"
        >Back to Login</a
      >

    </div>
    </form>
      </div>
    </div>
    </div>

  </div>


