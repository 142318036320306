<!-- <div class="container">

  <div nz-row nzGutter="16" class="toolbar" *ngIf="this.scrWidth >  678">
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="logo">
        <img src="assets/images/Group 1633.png" alt="logo" (click)="toHomePage()" />
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div nz-row nzJustify="center">
        <div nz-col nzSpan="8">
          <div class="title">
            <h2 class="text-title">E-biller</h2>
          </div>
        </div>
      </div>
    </div>
    <div nz-col class="gutter-row" nzSpan="8">
      <div class="gutter-box" nz-row nzJustify="end">
        <div nz-col nzSpan="12"></div>
        <div nz-col nzSpan="12" class="home-wrp">
          <button nz-button nzType="default" class="home-btn" (click)="toHomePage()">
            Home
            <i nz-icon nzType="home" nzTheme="outline"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="bluescreen">
    <div id="particles-js"></div>
    <div class="loginscreen shadow">
      <div class="title-login">
        Forgot Password
      </div>

      <form nz-form [formGroup]="emailResetForm" class="login-form" (ngSubmit)="emailReset()">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please enter your email" nzHasFeedback [nzErrorTip]="email">
            <nz-input-group nzPrefixIcon="mail">
              <input type="email" nz-input formControlName="email" placeholder="Email" [ngModel]="this.userEmail" maxlength="50" />
            </nz-input-group>
            <ng-template #email let-control>
              <ng-container *ngIf="control.hasError('email')">
                This is not a valid email address.
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <button nz-button class="login-form-button" [nzType]="'primary'" [disabled]="emailResetForm.invalid || loading"
          (click)="emailReset()" nzBlock [nzLoading]="loading">
          Send Email<i nz-icon nzType="mail" nzTheme="outline"></i>
        </button>
       <div nz-row>
          <a class="login-form-forgot" class="login-form-forgot" routerLink="/login/sms-reset">Send sms!</a>

        </div>
        <div nz-row>
          <a class="login-form-forgot" class="login-form-forgot" routerLink="/login">Back to Login!</a>

        </div>
      </form>
    </div>
  </div>
</div> -->


<div class="bluescreen">
  <div id="particles-js" class="particle-class"></div>
  <div nz-row nzType="flex" class="header-class"  [nzGutter]="16"  *ngIf="this.scrWidth >  678">
    <div nz-col nzSpan="8">
      <img
        class="stanbic-logo "
        src="assets/images/Group 1633.png"
        alt="logo"
        (click)="toHomePage()"
      />
    </div>
    <div nz-col nzSpan="8" style="height: 20vh" nzJustify="center">
      <h2
      class="ebiller-heading "
      >
        E-biller
      </h2>
    </div>
    <div
      nz-col
      nzSpan="8"
      class="home-wrp"
      nzJustify="end"
    >
      <button
        nz-button
        nzType="default"
        class="home-btn"
        (click)="toHomePage()"
      >
        Home
        <i nz-icon nzType="home" nzTheme="outline"></i>
      </button>
    </div>
  </div>

  <div nz-row nzJustify="center" class="ebiller-form ">

    <div class="actual-form" >
      <form
      autocomplete="off"
        nz-form
        [formGroup]="emailResetForm"
        class="login-form"
        (ngSubmit)="emailReset()"
      >
      <div nz-col nzSpan="8" *ngIf="this.scrWidth <  678">
        <img
        style=" padding-bottom:25px;"
          height="80"
          src="assets/images/logo.png"
          alt="logo"
          (click)="toHomePage()"
        />
      </div>
      <div nz-col style="height: 10vh" nzJustify="center" *ngIf="this.scrWidth <  678">
        <h2
       style="color: black; font-weight: 300; font-size: 4em; padding-bottom: 15px; text-align: center;  font-family: 'BentonSansProRegular';"
        >
          E-biller
        </h2>
      </div>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please enter your email" nzHasFeedback [nzErrorTip]="email">
          <nz-input-group nzPrefixIcon="mail">
            <input type="email" nz-input formControlName="email" placeholder="Email" [ngModel]="this.userEmail" maxlength="50" />
          </nz-input-group>
          <ng-template #email let-control>
            <ng-container *ngIf="control.hasError('email')">
              This is not a valid email address.
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <button nz-button class="login-form-button" [nzType]="'primary'" [disabled]="emailResetForm.invalid || loading"
      (click)="emailReset()" nzBlock [nzLoading]="loading">
      Send Email<i nz-icon nzType="mail" nzTheme="outline"></i>
    </button>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="16">
            <a
              class="login-form-forgot"
              class="login-form-forgot"
              routerLink="/login/sms-reset"
              >Send SMS!</a>

          </div>

        </div>
        <div nz-row class="login-form-margin">
          <div nz-col [nzSpan]="16" >
            <a
            class="login-form-forgot"
            class="login-form-forgot"
            routerLink="/login"
            >Back to Login</a
          >          </div>

        </div>

      </form>
    </div>

    <!-- <div id="particles-js"></div> -->
  </div>
</div>

